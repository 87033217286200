import './App.css';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@uidotdev/usehooks';

function App() {
  const colorMode = useMediaQuery("(prefers-color-scheme : dark)") ? "dark" : "light";

  const [secondsLeft, setSecondsLeft] = useState(0)
  const [minutesLeft, setMinutesLeft] = useState(0)
  const [hoursLeft, setHoursLeft] = useState(0)
  const [daysLeft, setDaysLeft] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()
      const target = new Date('2024-08-10 11:00')
      const diff = target - now

      setSecondsLeft(Math.abs(Math.floor((diff / 1000) % 60)))
      setMinutesLeft(Math.abs(Math.floor((diff / 1000 / 60) % 60)))
      setHoursLeft(Math.abs(Math.floor((diff / 1000 / 60 / 60) % 24)))
      setDaysLeft(Math.abs(Math.floor(diff / 1000 / 60 / 60 / 24)))
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <div className="hidden">
        <img src="/images/logo-light.png" alt="Light Logo" />
        <img src="/images/logo-dark.png" alt="Dark Logo" />
        <img src="/images/0.gif" alt="Number 0" />
        <img src="/images/1.gif" alt="Number 1" />
        <img src="/images/2.gif" alt="Number 2" />
        <img src="/images/3.gif" alt="Number 3" />
        <img src="/images/4.gif" alt="Number 4" />
        <img src="/images/5.gif" alt="Number 5" />
        <img src="/images/6.gif" alt="Number 6" />
        <img src="/images/7.gif" alt="Number 7" />
        <img src="/images/8.gif" alt="Number 8" />
        <img src="/images/9.gif" alt="Number 9" />
      </div>
      <div className="w-full h-screen bg-white dark:bg-black flex flex-col items-center justify-center">
        <img src={`/images/logo-${colorMode}.png`} className='w-1/2' />
        <div className="w-2/3 h-1/3 overflow-hidden flex flex-row gap-1 items-center justify-center">
          <div className="flex flex-col items-center">
            <div className="flex flex-row gap-1">
              {daysLeft.toString().padStart(2, '0').split('').map((digit) => {
                return <img src={`/images/${digit}.gif`} className="" alt="logo" />
              })}
            </div>
            <span className="font-extrabold text-2xl text-black dark:text-white">DAYS</span>
          </div>
          

          <div className="flex flex-col items-center">
            <span className="font-extrabold text-7xl text-black dark:text-white">:</span>
            <span className="font-extrabold text-2xl">&nbsp;</span>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex flex-row gap-1">
              {hoursLeft.toString().padStart(2, '0').split('').map((digit) => {
                return <img src={`/images/${digit}.gif`} className="" alt="logo" />
              })}
            </div>
            <span className="font-extrabold text-2xl text-black dark:text-white">HOURS</span>
          </div>

          <div className="flex flex-col items-center">
            <span className="font-extrabold text-7xl text-black dark:text-white">:</span>
            <span className="font-extrabold text-2xl">&nbsp;</span>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex flex-row gap-1">
              {minutesLeft.toString().padStart(2, '0').split('').map((digit) => {
                return <img src={`/images/${digit}.gif`} className="" alt="logo" />
              })}
            </div>
            <span className="font-extrabold text-2xl text-black dark:text-white">MINUTES</span>
          </div>

          <div className="flex flex-col items-center">
            <span className="font-extrabold text-7xl text-black dark:text-white">:</span>
            <span className="font-extrabold text-2xl">&nbsp;</span>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex flex-row gap-1">
              {secondsLeft.toString().padStart(2, '0').split('').map((digit) => {
                return <img src={`/images/${digit}.gif`} className="" alt="logo" />
              })}
            </div>
            <span className="font-extrabold text-2xl text-black dark:text-white">SECONDS</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
