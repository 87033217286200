import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const faviconEl = document.querySelector('link[rel="icon"]')

const themeChange = (event) => {
  if (event.matches) {
    faviconEl.setAttribute('href', '/favicon-dark.png')
  } else {
    faviconEl.setAttribute('href', '/favicon-light.png')
  }
}

const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
mediaQuery.addEventListener('change', themeChange)
themeChange(mediaQuery)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
